import headers from "../axios/headers";
import axiosFetch from "../axios/index";

class TenantService {
	async getAllTenants() {
		try {
			let result = await axiosFetch.get("/tenants");
			return result;
		} catch (err) {
			return err;
		}
	}

	async getPaymentHistory() {
		try {
			let result = await axiosFetch.get("/get_payment_history");
			return result;
		} catch (err) {
			return err;
		}
	}

	async generateReport(payload, axiosOptions = {}) {
		try {
			let result = await axiosFetch.post(
				"/optimized_games_played_report",
				payload,
				{ headers: headers(), ...axiosOptions }
			);
			return result;
		} catch (err) {
			return err;
		}
	}

	async getCuAdminTotalGames() {
		try {
			let result = await axiosFetch.get("/admin_total_completed_games", {
				headers: headers(),
			});
			return result;
		} catch (err) {
			return err;
		}
	}

	async getTenantTotalGamesPlayed(payload) {
		try {
			let result = await axiosFetch.post("/total_completed_games", payload, {
				headers: headers(),
			});
			return result;
		} catch (err) {
			return err;
		}
	}

	async getAdminMonthlyGrowthAndCount() {
		try {
			let result = await axiosFetch.get("/admin_monthly_growth_and_count", {
				headers: headers(),
			});
			return result;
		} catch (err) {
			return err;
		}
	}

	async getAdminMonthlyIncompleteGames() {
		try {
			let result = await axiosFetch.get(
				"/admin_monthly_incompleted_growth_games",
				{
					headers: headers(),
				}
			);
			return result;
		} catch (err) {
			return err;
		}
	}

	async getAdminMonthlyHighCutOff() {
		try {
			let result = await axiosFetch.get("/admin_monthly_high_cut_off", {
				headers: headers(),
			});
			return result;
		} catch (err) {
			return err;
		}
	}

	async getAdminMonthlyLowCutOff() {
		try {
			let result = await axiosFetch.get("/admin_monthly_low_cut_off", {
				headers: headers(),
			});
			return result;
		} catch (err) {
			return err;
		}
	}

	async getTenantMontlyGrowth(payload) {
		try {
			let result = await axiosFetch.post("/monthly_growth", payload, {
				headers: headers(),
			});
			return result;
		} catch (err) {
			return err;
		}
	}

	async uploadPortfolioData(payload) {
		try {
			let result = await axiosFetch.post("/add_payment_history", payload, {
				headers: headers(),
			});
			return result;
		} catch (err) {
			return err;
		}
	}

	async scoreByPercentile(payload) {
		try {
			let result = await axiosFetch.post(
				"/score-breakdown-by-percentile",
				payload,
				{
					headers: headers(),
				}
			);
			return result;
		} catch (err) {
			return err;
		}
	}

	async getCuRiskAssessment(payload) {
		try {
			let result = await axiosFetch.post("/cu_risk_assessment_opt", payload, {
				headers: headers(),
			});
			return result;
		} catch (err) {
			return err;
		}
	}

	async getBureauRiskAssessment(payload) {
		try {
			let result = await axiosFetch.post(
				"/bureau_risk_assessment_opt",
				payload,
				{
					headers: headers(),
				}
			);
			return result;
		} catch (err) {
			return err;
		}
	}

	async getCuNoBureauRiskAssessment(payload) {
		try {
			let result = await axiosFetch.post(
				"/cu_no_bureau_risk_assessment_opt",
				payload,
				{
					headers: headers(),
				}
			);
			return result;
		} catch (err) {
			return err;
		}
	}

	async getConfusionMatrix(payload) {
		try {
			let result = await axiosFetch.post("/get_confusion_matrix", payload, {
				headers: headers(),
			});
			return result;
		} catch (err) {
			return err;
		}
	}

	async getInternalUsers() {
		try {
			let result = await axiosFetch.get("/tenant_internal_users", {
				headers: headers(),
			});
			return result;
		} catch (err) {
			return err;
		}
	}

	async getUserIds(payload) {
		try {
			let result = await axiosFetch.post("/tenant_based_user_list", payload, {
				headers: headers(),
			});
			return result;
		} catch (err) {
			return err;
		}
	}

	async getUserGameInfo(payload) {
		try {
			let result = await axiosFetch.post("/user_traits_info", payload, {
				headers: headers(),
			});
			return result;
		} catch (err) {
			return err;
		}
	}

	async getScoreRiskSummary(payload) {
		try {
			let result = await axiosFetch.post("/score_to_risk_distribution_summary_graph", payload, {
				headers: headers(),
			});
			return result;
		} catch (err) {
			return err;
		}
	}

	async getBehavioralAnalysis(payload) {
		try {

			let result = await axiosFetch.post("/behavioral_analysis", payload, {
				headers: headers(),
			});
			return result;
		} catch (err) {
			return err;
		}
	}

	async getTenantMonthlyCompletedGames(payload) {
		try {

			let result = await axiosFetch.post("/monthly_growth", payload, {
				headers: headers(),
			});
			return result;
		} catch (err) {
			return err;
		}
	}

	async getTenantMonthlyIncompleteGames(payload) {
		try {

			let result = await axiosFetch.post("/monthly_incomplete_games", payload, {
				headers: headers(),
			});
			return result;
		} catch (err) {
			return err;
		}
	}
}

export default TenantService;
